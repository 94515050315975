import { Text } from '@ui/Text';
import { Input } from '@ui/Input';
import SearchIcon from '@mui/icons-material/Search';
import { BatteryStatus } from '@components/BatteryStatus';
import { useState } from 'react';
import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';

export function DataSensors() {
  const api = useApi();

  const [search, setSearch] = useState<string>('');

  const rfidCarriersQuery = useQuery({
    queryKey: ['rfidCarriers'],
    queryFn: api.rfidCarriers.getAllRfidCarriers,
  });

  let rfidCarriers = rfidCarriersQuery.data?.data.data || [];
  if (search) {
    rfidCarriers = rfidCarriers.filter((rc) => {
      const searchString = [rc.serial, rc.rfidTagUID, rc.container?.serial, rc.batteryLevel].join(';').toLowerCase();

      return searchString.includes(search.toLowerCase());
    });
  }

  return (
    <>
      <div className="h-[calc(100vh-114px)]">
        <div className="mb-5 flex h-full flex-col border-r-[1px] bg-white shadow-md">
          <div className="flex p-3 sm:p-6">
            <Input
              variant={'search'}
              LeadingIcon={<SearchIcon className="text-onSurface-highEmphasis" />}
              type="search"
              label="Suche"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="h-full overflow-x-auto overflow-y-auto border-t border-gray-100 pb-6">
            <table className="w-full table-auto whitespace-nowrap text-sm">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="px-2 py-4 pl-6 text-left">
                    <Text variant="heading3">Cap S/N</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">RFID-Chip</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Container S/N</Text>
                  </th>
                  <th className="px-2 py-4 pr-6 text-right">
                    <Text variant="heading3">Akkustand</Text>
                  </th>
                </tr>

                <tr>
                  {/* Workaround to get a bottom border for the header. Setting border-b to the sticky thead doesn't work because of the border-collapse. */}
                  <th colSpan={14} className="h-[1px] bg-gray-50"></th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-100 border-b border-gray-100">
                {rfidCarriers.map((rfidCarrier) => (
                  <tr key={rfidCarrier.id} className="hover:bg-surface-bright">
                    <td className="px-2 py-0.5 pl-6">
                      <Text className="font-mono" title={rfidCarrier.comment || undefined}>
                        {rfidCarrier.serial}
                      </Text>
                    </td>

                    <td className="px-2 py-0.5">
                      <Text className="font-mono">{rfidCarrier.rfidTagUID}</Text>
                    </td>

                    <td className="px-2 py-0.5">
                      <Text className="font-mono">{rfidCarrier.container?.serial || '-'}</Text>
                    </td>

                    <td className="px-2 py-0.5 pr-6">
                      <span className="flex items-center justify-end gap-2">
                        {rfidCarrier.batteryLevel ? (
                          <>
                            <Text className="font-mono">{rfidCarrier.batteryLevel}%</Text>
                            <BatteryStatus batteryLevel={rfidCarrier.batteryLevel} />
                          </>
                        ) : (
                          <>
                            <Text className="font-mono">-</Text>
                            <BatteryStatus batteryLevel={null} />
                          </>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
