import { z } from 'zod';
import CONFIG from '../config';
import { useSessionStore } from '@stores/sessionStore';

export class ApiInvalidSessionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ApiInvalidSessionError';
  }
}

/*******************************************************************************
 * Login
 ******************************************************************************/

type LoginParams = {
  data: {
    email: string;
    password: string;
  };
};

const LoginResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['user_not_found', 'too_many_login_attempts', 'password_incorrect']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      sessionToken: z.string(),
      sessionExpiresAt: z.coerce.date(),
    }),
  }),
]);

/*******************************************************************************
 * Get Current Session
 ******************************************************************************/

const GetCurrentSessionResponse = z.object({
  ok: z.literal(true),
  data: z.object({
    expiresAt: z.coerce.date(),
    createdAt: z.coerce.date(),
    user: z.object({
      id: z.string().uuid(),
      name: z.string(),
      email: z.string().email(),
      company: z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    }),
  }),
});

/*******************************************************************************
 * Get All Containers
 ******************************************************************************/

const GetAllContainersResponse = z.object({
  ok: z.literal(true),
  data: z.object({
    data: z.array(
      z.object({
        id: z.string().uuid(),
        serial: z.string(),
        type: z.enum(['ibc', 'tank_container']),
        lifetimeExpiresAt: z.coerce.date().nullable(),
        nextInspectionType: z.enum(['major', 'minor']).nullable(),
        isFilled: z.boolean(),
        temperature: z.number().nullable(),
        location: z
          .object({
            lng: z.number(),
            lat: z.number(),
            accuracy: z.number(),
            countryCode: z.string().nullable(),
            address: z.string().nullable(),
          })
          .nullable(),
        lastSeenAt: z.coerce.date().nullable(),
        lastMovedAt: z.coerce.date().nullable(),
        warningsAcknowledgedAt: z.coerce.date(),
        notAcknowledgedTemperatureWarningsCount: z.number().int(),
        notAcknowledgedShockWarningsCount: z.number().int(),
        rfidCarriers: z.array(
          z.object({
            id: z.string().uuid(),
            imei: z.string().nullable(),
            type: z.enum(['sensor']),
            rfidTagUID: z.string(),
            serial: z.string(),
            batteryLevel: z.number().int().nullable(),
          })
        ),
        zone: z
          .object({
            id: z.string().uuid(),
            name: z.string(),
          })
          .nullable(),
        chemical: z
          .object({
            id: z.string().uuid(),
            name: z.string(),
          })
          .nullable(),
      })
    ),
  }),
});

/*******************************************************************************
 * Get Container By ID
 ******************************************************************************/

type GetContainerByIdParams = {
  containerId: string;
};

const GetContainerByIdResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['container_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      id: z.string().uuid(),
      serial: z.string(),
      type: z.enum(['ibc', 'tank_container']),
      lifetimeExpiresAt: z.coerce.date().nullable(),
      nextInspectionType: z.enum(['major', 'minor']).nullable(),
      isFilled: z.boolean(),
      temperature: z.number().nullable(),
      minTemperature: z.number().nullable(),
      maxTemperature: z.number().nullable(),
      location: z
        .object({
          lng: z.number(),
          lat: z.number(),
          accuracy: z.number(),
          countryCode: z.string().nullable(),
          address: z.string().nullable(),
        })
        .nullable(),
      lastSeenAt: z.coerce.date().nullable(),
      lastMovedAt: z.coerce.date().nullable(),
      warningsAcknowledgedAt: z.coerce.date(),
      rfidCarriers: z.array(
        z.object({
          id: z.string().uuid(),
          type: z.enum(['sensor']),
          rfidTagUID: z.string(),
          serial: z.string(),
          batteryLevel: z.number().int().nullable(),
        })
      ),
      zone: z
        .object({
          id: z.string().uuid(),
          name: z.string(),
        })
        .nullable(),
      chemical: z
        .object({
          id: z.string().uuid(),
          name: z.string(),
        })
        .nullable(),
      history: z.array(
        z.object({
          id: z.string().uuid(),
          location: z
            .object({
              lng: z.number(),
              lat: z.number(),
              accuracy: z.number().nullable(),
              countryCode: z.string().nullable(),
              address: z.string().nullable(),
            })
            .nullable(),
          zone: z
            .object({
              id: z.string().uuid(),
              name: z.string(),
            })
            .nullable(),
          temperature: z
            .object({
              temperature: z.number(),
            })
            .nullable(),
          shock: z
            .object({
              intensity: z.number().nullable(),
            })
            .nullable(),
          isFilled: z.boolean(),
          batteryLevel: z.number(),
          recordedAt: z.coerce.date(),
          createdAt: z.coerce.date(),
        })
      ),
    }),
  }),
]);

/*******************************************************************************
 * Create Container
 ******************************************************************************/

type CreateContainerParams = {
  data: {
    serial: string;
    type: 'ibc' | 'tank_container';
  };
};

const CreateContainerResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['serial_is_already_in_use']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      id: z.string().uuid(),
      serial: z.string(),
      location: z.literal(null),
      rfidCarriers: z.array(
        z.object({
          id: z.string().uuid(),
          type: z.enum(['sensor']),
          rfidTagUID: z.string(),
          serial: z.string(),
          batteryLevel: z.number().int().nullable(),
        })
      ),
    }),
  }),
]);

/*******************************************************************************
 * Update Container
 ******************************************************************************/

type UpdateContainerParams = {
  containerId: string;
  data: {
    isFilled?: boolean;
    type?: 'ibc' | 'tank_container';
    lifetimeExpiresAt?: Date;
    chemicalId?: string;
    warningsAcknowledgedAt?: Date;
    nextInspectionType?: 'major' | 'minor' | null;
  };
};

const UpdateContainerResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['container_not_found', 'chemical_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.literal(null),
  }),
]);

/*******************************************************************************
 * Get Container History CSV
 ******************************************************************************/

type GetContainerHistoryCSVParams = {
  containerId: string;
};

const GetContainerHistoryCSVResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['container_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.instanceof(Blob),
  }),
]);

/*******************************************************************************
 * Get RFID Carrier By RFID Tag UID
 ******************************************************************************/

type GetRfidCarrierByRfidTagUIDParams = {
  rfidTagUID: string;
};

const GetRfidCarrierByRfidTagUIDResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['rfid_carrier_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      id: z.string().uuid(),
      type: z.enum(['sensor']),
      rfidTagUID: z.string(),
      serial: z.string(),
      batteryLevel: z.number().int().nullable(),
      containerId: z.string().uuid().nullable(),
    }),
  }),
]);

/*******************************************************************************
 * Update RFID Carrier
 ******************************************************************************/

type UpdateRfidCarrierParams = {
  id: string;
  data: {
    containerId: string | null;
  };
};

const UpdateRfidCarrierResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['rfid_carrier_not_found', 'container_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      id: z.string().uuid(),
      type: z.enum(['sensor']),
      rfidTagUID: z.string(),
      serial: z.string(),
      batteryLevel: z.number().int().nullable(),
      containerId: z.string().uuid().nullable(),
    }),
  }),
]);

/*******************************************************************************
 * Get All RFID Carriers
 ******************************************************************************/

const GetAllRfidCarriersResponse = z.object({
  ok: z.literal(true),
  data: z.object({
    data: z.array(
      z.object({
        id: z.string().uuid(),
        type: z.enum(['sensor']),
        rfidTagUID: z.string(),
        serial: z.string(),
        batteryLevel: z.number().int().nullable(),
        comment: z.string().nullable(),
        container: z
          .object({
            id: z.string().uuid(),
            serial: z.string(),
            type: z.enum(['ibc', 'tank_container']),
          })
          .nullable(),
      })
    ),
  }),
});

/*******************************************************************************
 * Get All Zones
 ******************************************************************************/

const GetAllZonesResponse = z.object({
  ok: z.literal(true),
  data: z.object({
    data: z.array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        area: z.object({
          type: z.literal('Polygon'),
          coordinates: z.array(z.array(z.array(z.number()))),
        }),
        containerEnterAction: z.enum(['set_container_filled', 'set_container_empty']).nullable(),
        containerLeaveAction: z.enum(['set_container_filled', 'set_container_empty']).nullable(),
      })
    ),
  }),
});

/*******************************************************************************
 * Get All Chemicals
 ******************************************************************************/

const GetAllChemicalsResponse = z.object({
  ok: z.literal(true),
  data: z.object({
    data: z.array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      })
    ),
  }),
});

/*******************************************************************************
 * Public - Get RFID Carrier by RFID Tag UID
 ******************************************************************************/

type PublicGetRFIDCarrierBySerialParams = {
  serial: string;
};

const PublicGetRFIDCarrierByRFIDTagUIDResponse = z.discriminatedUnion('ok', [
  z.object({
    ok: z.literal(false),
    data: z.object({
      errCode: z.enum(['rfid_carrier_not_found']),
      message: z.string(),
    }),
  }),
  z.object({
    ok: z.literal(true),
    data: z.object({
      id: z.string().uuid(),
      type: z.enum(['sensor']),
      rfidTagUID: z.string(),
      batteryLevel: z.number().int().nullable(),
      container: z
        .object({
          id: z.string().uuid(),
          serial: z.string(),
          temperature: z.number().nullable(),
          minTemperature: z.number().nullable(),
          maxTemperature: z.number().nullable(),
          chemical: z
            .object({
              id: z.string().uuid(),
              name: z.string(),
            })
            .nullable(),
        })
        .nullable(),
    }),
  }),
]);

export function useApi() {
  const sessionStore = useSessionStore();

  function checkResponseAuth({ status }: { status: number }) {
    if (status !== 401) {
      return;
    }

    sessionStore.clearSession();
    localStorage.removeItem('session_token');
    throw new ApiInvalidSessionError();
  }

  const api = {
    auth: {
      login: async (params: LoginParams) => {
        const url = `${CONFIG.API_URI}/v1/auth/login`;

        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params.data),
        });

        const resData = await res.json();

        const response = LoginResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      getCurrentSession: async () => {
        const url = `${CONFIG.API_URI}/v1/auth/currentSession`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetCurrentSessionResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },

    containers: {
      getAllContainers: async () => {
        const url = `${CONFIG.API_URI}/v1/containers`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetAllContainersResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      getContainerById: async (params: GetContainerByIdParams) => {
        const url = `${CONFIG.API_URI}/v1/containers/${params.containerId}`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetContainerByIdResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      createContainer: async (params: CreateContainerParams) => {
        const url = `${CONFIG.API_URI}/v1/containers`;

        const res = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params.data),
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = CreateContainerResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      updateContainer: async (params: UpdateContainerParams) => {
        const url = `${CONFIG.API_URI}/v1/containers/${params.containerId}`;

        const res = await fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params.data),
        });

        checkResponseAuth(res);

        let resData = null;
        if (!res.ok) {
          resData = await res.json();
        }

        const response = UpdateContainerResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      getContainerHistoryCSV: async (params: GetContainerHistoryCSVParams) => {
        const url = `${CONFIG.API_URI}/v1/containers/${params.containerId}/history.csv`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.blob();

        const response = GetContainerHistoryCSVResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },

    rfidCarriers: {
      getRfidCarrierByRfidTagUID: async (params: GetRfidCarrierByRfidTagUIDParams) => {
        const url = `${CONFIG.API_URI}/v1/rfidCarriers/byRfidTagUID/${params.rfidTagUID}`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetRfidCarrierByRfidTagUIDResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      updateRfidCarrier: async (params: UpdateRfidCarrierParams) => {
        const url = `${CONFIG.API_URI}/v1/rfidCarriers/${params.id}`;

        const res = await fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params.data),
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = UpdateRfidCarrierResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },

      getAllRfidCarriers: async () => {
        const url = `${CONFIG.API_URI}/v1/rfidCarriers`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetAllRfidCarriersResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },

    zones: {
      getAllZones: async () => {
        const url = `${CONFIG.API_URI}/v1/zones`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetAllZonesResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },

    chemicals: {
      getAllChemicals: async () => {
        const url = `${CONFIG.API_URI}/v1/chemicals`;

        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionStore.session?.sessionToken}`,
          },
        });

        checkResponseAuth(res);

        const resData = await res.json();

        const response = GetAllChemicalsResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },

    public: {
      getRFIDCarrierBySerial: async (params: PublicGetRFIDCarrierBySerialParams) => {
        const url = `${CONFIG.API_URI}/v1/public/rfidCarriers/bySerial/${params.serial}`;

        const res = await fetch(url);

        const resData = await res.json();

        const response = PublicGetRFIDCarrierByRFIDTagUIDResponse.parse({
          ok: res.ok,
          data: resData,
        });

        return response;
      },
    },
  };

  return api;
}
